(function() {
  function sp_wcookie(name, value, expire_in_hours) {
    var d = new Date();
    d.setTime(d.getTime() + (parseInt(expire_in_hours) * 60 * 60 * 1000));
    document.cookie = "" + name + "=" + value + "; expires=" + d.toUTCString() + "; path=/";
  };

  function getCookie(cname) {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
        c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
    }
    }
    return null;
  }

  function clearListCookies(){
      var cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++)
      {
          var spcook =  cookies[i].split("=");
          deleteCookie(spcook[0]);
      }

      function deleteCookie(cookiename)
      {
          var name=cookiename;;
          var value="";
          document.cookie = name + "=" + value + "; path=/; Max-Age=-1";
      }
  }

  function parceiros_promo_hook() {
    var search = window.location.search.substr(1, window.location.search.length);

    if ((search.indexOf("utm_medium=afiliado") === -1) && (search.indexOf("utm_medium=parceirospromo") === -1)) return;

    clearListCookies();

    var params = search.split("&");

    var pa_data = {"pcrslug": "", "pcrid": "", "utm_source": "", "utm_medium": "", "pcrtt": "", "cupom": "",
                   "fb_pixel": ""};
    var actionpay_data = {"source": "", "apclick": "", "apsource": "", "actionpay": ""};
    var meliuz_data = {"xtra": ""};

    var data_objs = [pa_data, actionpay_data, meliuz_data];
    var newQuery = "";

    for (var i=0; i<params.length; i++) {
      var spl = params[i].split("=");

      // if (params[i].indexOf('utm_medium') === -1) {
      //   newQuery += (newQuery ? '&' : '') + params[i];
      // }

      newQuery += (newQuery ? '&' : '') + params[i];

      if (spl.length < 1) continue;

      var field = spl[0];
      var value = spl[1];

      if (field == 'tt' && (params.filter(el => el.substring(0, 5) == 'pcrtt')).length == 0 ) {
        field = 'pcrtt'
      }

      for (var j=0; j<data_objs.length; j++) {
        if (Object.keys(data_objs[j]).indexOf(field) !== -1) {
          data_objs[j][field] = value;
          break;
        }
      }
    }

    if(pa_data['pcrid']){
      var partner_slug_url = "/site/partner-slug/" + pa_data['pcrid'] +"/";
      var partner_slug_request = new XMLHttpRequest();

      partner_slug_request.open("GET", partner_slug_url, true);
      partner_slug_request.send();
    }

    var newLocation = window.location.pathname;
    if (pa_data.pcrslug) {
      newLocation = newLocation.replace('/site/', '/p/' + pa_data.pcrslug + '/')
    }

    try {
      history.pushState({}, document.title, newLocation + '?' + newQuery);
    }
    catch(e) {
      window.location = newLocation + '?' + newQuery;
    }


    if (typeof(pa_data.utm_medium) != "undefined" && (pa_data.utm_medium == 'afiliado' || pa_data.utm_medium == 'parceirospromo')) {
      document.cookie = "partner=; domain=.segurospromo.com.br; path=/; Max-Age=-1";
      document.cookie = "partner=; domain=www.segurospromo.com.br; path=/; Max-Age=-1";

      if (actionpay_data['apclick'] || actionpay_data['apsource']) {
        actionpay_data['actionpay'] = actionpay_data['apclick'] + '.' + actionpay_data['apsource'];
      }

      var keys = Object.keys(pa_data);
      for (var i=0; i<keys.length; i++) {
        sp_wcookie(keys[i], pa_data[keys[i]], keys[i] === 'cupom' ? 1*24 : 24*15);
      }

      keys = Object.keys(actionpay_data);
      for (var i=0; i<keys.length; i++) {
        sp_wcookie('actionpay_' + keys[i], actionpay_data[keys[i]], 24*7);
      }

      keys = Object.keys(meliuz_data);
      for (var i=0; i<keys.length; i++) {
        sp_wcookie('meliuz_' + keys[i], meliuz_data[keys[i]], 24*7);
      }

      var request = new XMLHttpRequest();
      request.onreadystatechange = function() {
        if ((request.readyState === 4) && (request.status !== 200)) {
          console.log(request.responseText);
        }
      }
      // var url = '/p/' + (pa_data.pcrslug || ('id-' + pa_data.pcrid)) + '/parceiro-js/?tt=' + pa_data.pcrtt + '&cupom=' + pa_data.cupom;
      // request.open('GET', url);
      // request.send();

      try {
        pa('custom', 'affiliate-hook', {fields: pa_data});
      } catch(ex) {
        console.log(ex);
      }
    }
  };

  function cityads_hook() {
    if(window.location.pathname.indexOf("pos_compra") !== -1 || window.location.pathname.indexOf("aereo/pagamento") !== -1) {
        return;
    }

    var keyValuePairs = location.search.slice(1).split("&");
    var d = new Date();
    var dias = 30;

    /* Quantidade de dias para o Cookie estar disponível */
    d.setTime(d.getTime() + (dias*24*60*60*1000));
    var expires = "expires="+d.toGMTString();

    keyValuePairs.forEach(function(keyValuePair) {
        keyValuePair = keyValuePair.split('=');
        if(keyValuePair[0] === 'click_id') {
            document.cookie = 'clickCityAdsID='+keyValuePair[1] + "; expires=" + expires +"; path=/";
        }
    });
}

  function partner_fb_pixel() {
    pxl = getCookie("fb_pixel");
    if(!pxl) return;

    !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
    n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
    document,'script','https://connect.facebook.net/en_US/fbevents.js');

    fbq('init', pxl);
    fbq('track', 'PageView');
  }

  function pcr_fingerprint() {
      try {
        pa('custom', 'fingerprint', {
          viewport: {
            w: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
            h: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
        }});
      } catch(ex) {
        console.log(ex);
      }
  }

  parceiros_promo_hook();
  partner_fb_pixel();
  cityads_hook();
  pcr_fingerprint();
})();
